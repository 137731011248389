import React, { Component } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import queryString from 'query-string';
import { motion } from 'framer-motion';

import Arrow from '../components/ButtonNotUnderlined/assets/arrow.svg';
import Facebook from '../images/assets/socialIcons/facebookDark.svg';
import LinkedIn from '../images/assets/socialIcons/linkedinDark.svg';
import Instagram from '../images/assets/socialIcons/instagram.png';
import FooterPhoto from '../images/footer/footerPhone.svg';
// import CrimsonBlack from '../images/assets/Logos/crimson_black.png';
// import VioletBlack from '../images/assets/Logos/violet_black.png';
// import TealBlack from '../images/assets/Logos/teal_black.png';
import CrimsonBlack from '../images/assets/Logos/reload-s360.svg';

import linkStripper from '../utils/link-stripper';
import { NEWSLETTER_LIST_ID, NEWSLETTER_URL } from '../utils/constants';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
  bottom: 0;
  width: 100%;
  padding-top: 60px;
  @media (max-width: 1025px) {
    padding-top: 0px;
    margin-bottom: 5px;
  }
`;

const FooterInnerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 1440px;
  width: 100%;
  @media (max-width: 1025px) {
    width: 90%;
  }
`;

const LogoContainer = styled.div`
  svg {
    width: 120px;

    @media (max-width: 1025px) {
      width: 80px;
    }
  }
`;

const FooterMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 1300px) {
    justify-content: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 1025px) {
    flex-wrap: wrap;
    margin-top: 15px;
    padding: 0px;
  }
`;

const FooterMenuColumnContact = styled.div`
  height: 100%;
  @media (max-width: 1025px) {
    height: unset;
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const FooterMenuColumn = styled.div`
  height: 100%;
  @media (max-width: 1300px) {
    width: 140px;
  }
  @media (max-width: 1025px) {
    height: unset;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const FooterMenuColumnNewsletter = styled.div`
  height: 100%;
  @media (max-width: 1300px) {
    width: 100%;
    height: 100px;
  }
  @media (max-width: 1025px) {
    height: unset;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const FooterItem = styled.a`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -2%;
  margin-bottom: 15px;
  margin-top: 15px;
  cursor: pointer;
  transition: 650ms;
  width: 100%;
  @media (max-width: 1025px) {
    margin-bottom: 0px;
    margin-top: 10px;
    margin-right: 20px;
  }
  :hover {
    color: #000;
    transition: 650ms;
  }
`;

const FooterItemNoA = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -2%;
  margin-bottom: 15px;
  margin-top: 15px;
  cursor: pointer;
  transition: 650ms;
  width: 100%;
  @media (max-width: 1025px) {
    margin-bottom: 0px;
    margin-top: 10px;
    margin-right: 20px;
  }
  :hover {
    color: #000;
    transition: 650ms;
  }
`;

const FooterTitle = styled.div`
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -2%;
  color: #fff;
  line-height: 28px;
  text-transform: uppercase;
  font-style: normal;
  margin-bottom: 10px;
  @media (max-width: 1025px) {
    width: 100%;
    margin-bottom: 0px;
  }
`;

const PhoneContainerOuter = styled.div`
  width: unset;
  @media (max-width: 1025px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const PhoneContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 0px;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 6.5%;
  display: flex;
  padding: 10px 20px 7px 10px;
  height: 17px;
  @media (max-width: 1025px) {
    width: 120px;
    padding-left: 15px;
  }
`;

const PhoneImage = styled.div`
  margin-right: 10px;
  margin-left: 5px;
  padding-top: 1px;
`;

const PhoneText = styled.a``;

const FooterAddressContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 1025px) {
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    width: 100%;
  }
`;

const FooterAddressItem = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  color: #fff;
  @media (max-width: 1025px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  @media (max-width: 1025px) {
    margin-left: 0px;
    margin-top: -5px;
  }
`;

const SocialItem = styled.a`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  @media (max-width: 1025px) {
    display: none;
  }
`;

const SocialContainerMobile = styled.div`
  display: none;
  @media (max-width: 1025px) {
    display: flex;
    margin-left: 10px;
  }
`;

const SocialItemMobileOuter = styled.div`
  display: flex;
  align-items: center;
  margin-top: -5px;
`;

const SocialItemMobile = styled.a`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  @media (max-width: 330px) {
    height: 16px;
    width: 16px;
  }
`;

const LogoImg = styled.img`
  width: 120px;
  @media (max-width: 1025px) {
    width: 80px;
  }
`;

const FooterItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FooterItemContainerAlt = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 300px;
`;

const LogoSectionContainerOuter = styled.div`
  display: flex;
  justify-content: justify-content;
  max-width: 1440px;
  width: 100%;
  padding-left: 50px;
  margin-bottom: 10px;
  @media (max-width: 1025px) {
    width: 90%;
    padding-left: 0;
    margin: 0;
  }
`;

const LogoSectionContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100px;
  display: flex;
  align-items: center;
  @media (max-width: 1025px) {
    height: 0px;
    margin-top: 50px;
    margin-bottom: 15px;
  }
`;

const SubscribeFormContainer = styled.div`
  width: 300px;
  background-color: #f5f5f5;
  height: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SubscribeFormMessage = styled.div`
  width: 300px;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SubscribeFormInput = styled.input`
  width: 160px;
  height: 35px;
  background-color: #f5f5f5;
  border-width: 0px;
  outline: none;
  padding-left: 10px;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const Divider = styled.div`
  height: 20px;
  background-color: #e1e1e1;
  width: 1px;
  margin-right: 25px;
`;

const SubscribeButton = styled(motion.button)`
  display: flex;
  border-width: 0px;
  border-style: solid;
  border-color: ${props => props.color};
  width: fit-content;
  margin: ${props => props.margin};
  cursor: pointer;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  background-color: transparent;
  font-size: ${props => props.fontSize};
  @media (max-width: 735px) {
    font-size: ${props => props.fontSizeMobile};
  }
  color: ${props => props.color};
`;

const ArrowContainer = styled(motion.div)`
  margin-left: 10px;
  padding-top: 1px;
  display: flex;
  align-items: center;
`;

const ButtonNotUnderlinedContainerInner = styled.div`
  display: flex;
`;

const LoadingText = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const ErrorMsgContainer = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: -10px;
`;

const InstagramImg = styled.img`
  height: 18px;
  width: 18px;
  filter: brightness(100);
  @media (max-width: 330px) {
    height: 16px;
    width: 16px;
  }
`;

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
      inputValue: '',
      errorMsg: false,
      isLoading: false,
      disabled: false,
      signedUp: false,
      buttonActive: false,
      signingUp: false,
    };
  }

  updateInputValue = evt => {
    //console.log(evt);
    this.setState({
      inputValue: evt.target.value,
    });
  };

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  toggleButtonActive = () => {
    if (this.state.buttonActive) {
      this.setState({
        buttonActive: false,
      });
    } else {
      this.setState({
        buttonActive: true,
      });
    }
    //console.log(this.state.buttonActive);
  };

  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      signingUp: true,
    });
    const { inputValue, errorMsg } = this.state;

    if (this.validateEmail(inputValue)) {
      this.setState({ errorMsg: false });

      const options = {
        method: 'POST',
        headers: {
          revision: '2023-09-15',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              custom_source: 'testing',
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email: inputValue,
                  },
                },
              },
            },
            relationships: {
              list: {
                data: {
                  type: 'list',
                  id: NEWSLETTER_LIST_ID,
                },
              },
            },
          },
        }),
      };

      fetch(
        'https://a.klaviyo.com/client/subscriptions/?company_id=P5zdyu',
        options
      )
        .then(response => {
          this.setState({
            signedUp: true,
            signingUp: false,
          });
          console.log('success');
        })
        .catch(err => {
          console.log('error');
          this.setState({
            signingUp: false,
          });
        });
    } else {
      this.setState({ errorMsg: true });
      this.setState({
        signingUp: false,
      });
    }
  };

  render() {
    return (
      <FooterContainer>
        <LogoSectionContainerOuter>
          <LogoSectionContainer>
            {this.props.footer_logo === 'crimson_black' ? (
              <LogoContainer>
                <CrimsonBlack />
              </LogoContainer>
            ) : null}
            {this.props.footer_logo === 'teal_black' ? (
              <LogoContainer>
                <CrimsonBlack />
              </LogoContainer>
            ) : null}
            {this.props.footer_logo === 'violet_black' ? (
              <LogoContainer>
                <CrimsonBlack />
              </LogoContainer>
            ) : null}
            <SocialContainerMobile>
              <SocialItemMobileOuter>
                <SocialItemMobile
                  href={this.props.footerData.linkedin_link.url}
                  target={this.props.footerData.linkedin_link.target}
                >
                  <LinkedIn />
                </SocialItemMobile>
                <SocialItemMobile
                  href={this.props.footerData.instagram_link.url}
                  target={this.props.footerData.instagram_link.target}
                >
                  <InstagramImg src={Instagram} alt="instagram" />
                </SocialItemMobile>
              </SocialItemMobileOuter>
            </SocialContainerMobile>
          </LogoSectionContainer>
        </LogoSectionContainerOuter>
        <FooterInnerContainer>
          <FooterMenuContainer>
            <FooterMenuColumnNewsletter>
              <FooterTitle>
                {this.props.footerData.newsletter_title.text}
              </FooterTitle>
              <FooterItemContainerAlt>
                {this.state.signingUp ? (
                  <LoadingText>Signing Up...</LoadingText>
                ) : (
                  <React.Fragment>
                    {this.state.signedUp ? (
                      <SubscribeFormMessage>
                        Thank you for signing up! Please confirm your email
                        address.
                      </SubscribeFormMessage>
                    ) : (
                      <React.Fragment>
                        {this.state.errorMsg && (
                          <ErrorMsgContainer>
                            Please enter a valid email address
                          </ErrorMsgContainer>
                        )}
                        <form onSubmit={this.onSubmit}>
                          <SubscribeFormContainer>
                            <SubscribeFormInput
                              name={'email'}
                              type={'email'}
                              value={this.state.inputValue}
                              onChange={this.updateInputValue}
                              placeholder={'Email Address'}
                            />
                            <Divider />
                            <SubscribeButton
                              type="submit"
                              whileHover={{ translateX: '5px' }}
                              onHoverStart={() => this.toggleButtonActive()}
                              onHoverEnd={() => this.toggleButtonActive()}
                            >
                              Submit
                              <ArrowContainer
                                color={'#A8A8A8'}
                                animate={
                                  this.state.buttonActive
                                    ? { translateX: '10px' }
                                    : { translateX: '0px' }
                                }
                                transition={{ duration: 0.5 }}
                              >
                                <Arrow
                                  style={{
                                    fill: '#A8A8A8',
                                  }}
                                />
                              </ArrowContainer>
                            </SubscribeButton>
                          </SubscribeFormContainer>
                        </form>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </FooterItemContainerAlt>
            </FooterMenuColumnNewsletter>
            <FooterMenuColumn>
              <FooterTitle>
                {this.props.footerData.explore_title.text}
              </FooterTitle>
              <FooterItemContainer>
                {this.props.footerData.explore_links.map((item, index) => (
                  <FooterItem
                    key={index}
                    href={linkStripper(item.explore_link.url)}
                    target={item.explore_link.target}
                  >
                    {item.link_text.text}
                  </FooterItem>
                ))}
              </FooterItemContainer>
            </FooterMenuColumn>
            <FooterMenuColumn>
              <FooterTitle>
                {this.props.footerData.company_title.text}
              </FooterTitle>
              <FooterItemContainer>
                {this.props.footerData.company_links.map((item, index) => (
                  <FooterItem
                    key={index}
                    href={linkStripper(item.link.url)}
                    target={item.link.target}
                  >
                    {item.link_text.text}
                  </FooterItem>
                ))}
              </FooterItemContainer>
            </FooterMenuColumn>
            <FooterMenuColumn>
              <FooterTitle>
                {this.props.footerData.legal_title.text}
              </FooterTitle>
              <FooterItemContainer>
                {this.props.footerData.legal_links.map((item, index) => (
                  <FooterItem
                    key={index}
                    href={linkStripper(item.link.url)}
                    target={item.link.target}
                  >
                    {item.link_text.text}
                  </FooterItem>
                ))}
              </FooterItemContainer>
            </FooterMenuColumn>
            <FooterMenuColumnContact>
              <FooterTitle>
                {this.props.footerData.find_us_title.text}
              </FooterTitle>
              {/*
              <PhoneContainerOuter>
                <PhoneContainer>
                  <PhoneImage>
                    <FooterPhoto />
                  </PhoneImage>
                  <PhoneText
                    href={'tel:' + this.props.footerData.telephone_number.text}
                  >
                    {this.props.footerData.telephone_number.text}
                  </PhoneText>
                </PhoneContainer>
              </PhoneContainerOuter>
              */}
              <FooterAddressContainer>
                <FooterAddressItem>
                  {this.props.footerData.address_details.text}
                </FooterAddressItem>
                <FooterAddressItem>
                  {this.props.footerData.address_details_two.text}
                </FooterAddressItem>
              </FooterAddressContainer>
              <FooterItemNoA>
                <SocialContainer>
                  {/*
                  <SocialItem
                    href={this.props.footerData.facebook_link.url}
                    target={this.props.footerData.facebook_link.target}
                  >
                    <Facebook />
                  </SocialItem>
                  */}
                  <SocialItem
                    href={this.props.footerData.linkedin_link.url}
                    target={this.props.footerData.linkedin_link.target}
                  >
                    <LinkedIn />
                  </SocialItem>
                  <SocialItem
                    href={this.props.footerData.instagram_link.url}
                    target={this.props.footerData.instagram_link.target}
                  >
                    <InstagramImg src={Instagram} alt="instagram" />
                  </SocialItem>
                </SocialContainer>
              </FooterItemNoA>
            </FooterMenuColumnContact>
          </FooterMenuContainer>
        </FooterInnerContainer>
      </FooterContainer>
    );
  }
}

export default Footer;
